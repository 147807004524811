<template>
  <div class="empty-news-items">
    <h4 class="empty-news-items__title">Нет новостей :(</h4>
  </div>
</template>

<script>
export default {
  name: "EmptyNewsItems",
  mixins: [],
  props: [],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.empty-news-items {
  margin: 100px auto 0 auto;
  display: flex;
  flex-direction: column;

  @media (max-width: $md) {
    width: 350px;
  }

  @media (max-width: $sm) {
    width: 280px;
    margin: 100px auto 150px auto;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__title {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 24px;

    text-align: center;
    font-weight: 400;
    color: $text-subdued;
  }

  &__text {
    width: 400px;
    margin: 0;
    padding: 0;

    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $color-text-primary-1;

    @media (max-width: $md) {
      width: 100%;
    }
  }
}
</style>
